import Button from './shared/button/Button';

export function Home() {
  return (
    <>
      <h1>Words Memorizer</h1>
      <p>Read books. Save unfamiliar words. Memorize</p>

      <Button type={'link'} title={"Let's go"} toLink={'/bundles'}></Button>
    </>
  );
}
