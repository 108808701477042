import React, { useState } from 'react';
import { useParams, Outlet, useOutletContext } from 'react-router-dom';
import { isDefined } from '../../utils/utils';
import ToggleButton from '../../shared/toggle-button/ToggleButton';
import { getValue, setValue } from '../../utils/local-storage.utils';
import Button from '../../shared/button/Button';
import styles from './MemorizationPage.module.scss';
import { PRIMARY_LANG } from '../../config';

const MemorizationPage = () => {
  const bundle = useOutletContext();
  const [direction, setDirection] = useState(getTranslationDirection(bundle.name, bundle.direction));
  const [showTranslation, setShowTranslation] = useState(false);
  let { chunkOrder, wordOrder } = useParams();

  const chunk = findChunk(bundle, chunkOrder);
  const isWordScreen = isDefined(wordOrder);

  const foreignToNativeDirection = `${chunk.lang}-${PRIMARY_LANG}`;
  const nativeToForeignDirection = `${PRIMARY_LANG}-${chunk.lang}`;

  chunkOrder = parseInt(chunkOrder);
  wordOrder = wordOrder ? parseInt(wordOrder) : wordOrder;

  return (
    <>
      {!!chunk && (
        <div className={styles.chunkContainer}>
          <div className={styles.topSection}>
            <h2 className={styles.wordCount}>Words: {chunk.words.length}</h2>
            {isWordScreen && (
              <div className={styles.translationDirectionSwitch}>
                <ToggleButton
                  left={{ label: foreignToNativeDirection, value: foreignToNativeDirection }}
                  right={{ label: nativeToForeignDirection, value: nativeToForeignDirection }}
                  value={direction}
                  onChange={(direction) => setTranslationDirection(bundle.name, direction)}
                />
              </div>
            )}
          </div>
          <div className={styles.middleSection}>
            <Outlet
              context={{
                words: chunk.words,
                settings: { isReverseDirection: direction !== foreignToNativeDirection, showTranslation },
              }}
            />
          </div>
          <div className={styles.bottomSection}>
            {isWordScreen ? (
              <>
                <div className={`${styles.button}`}>
                  {wordOrder > 1 && (
                    <Button
                      type={'link'}
                      buttonStyle={'secondaryOutline'}
                      title={'Previous'}
                      toLink={`words/${wordOrder - 1}`}
                      hostClass={styles.buttonFullWidth}
                    />
                  )}
                </div>
                <div className={`${styles.button} ${styles.buttonCenter}`}>
                  <Button
                    type={'button'}
                    buttonStyle={'primary'}
                    title={showTranslation ? 'Hide' : 'Show'}
                    onClick={() => setShowTranslation(!showTranslation)}
                    hostClass={styles.buttonFullWidth}
                  />
                </div>

                <div className={`${styles.button}`}>
                  {wordOrder < chunk.words.length && (
                    <Button
                      type={'link'}
                      buttonStyle={'secondaryOutline'}
                      title={'Next'}
                      toLink={`words/${wordOrder + 1}`}
                      hostClass={styles.buttonFullWidth}
                    />
                  )}
                </div>
              </>
            ) : (
              <Button
                type={'link'}
                buttonStyle={'primary'}
                title={'PROCEED'}
                toLink={`./words/${chunk.words[0].order}`}
                hostClass={styles.buttonFullWidth}
              />
            )}
          </div>
        </div>
      )}
    </>
  );

  function findChunk(bundle, chunkOrder) {
    let chunk = null;
    if (bundle.chunks) {
      chunk = bundle.chunks.find((chunk) => `${chunk.order}` === chunkOrder);
    }

    return chunk;
  }

  function setTranslationDirection(bundleName, direction) {
    const value = getValue(bundleName);
    value.direction = direction;

    setValue(bundleName, value);
    setDirection(direction);
  }

  function getTranslationDirection(bundleName, defaultDirection) {
    return getValue(bundleName).direction || defaultDirection;
  }
};

export default MemorizationPage;
