import styles from './Button.module.scss';
import { Link } from 'react-router-dom';

const buttonStyleCssClass = {
  primary: 'btnPrimary',
  primaryOutline: 'btnPrimaryOutline',
  secondary: 'btnSecondary',
  secondaryOutline: 'btnSecondaryOutline',
};

// type - button, link
// style - primary, primaryOutline, secondary, secondaryOutline
const Button = (props) => {
  const { type = 'button', buttonStyle = 'primary', title, onClick, toLink, hostClass } = props;

  return (
    <>
      {type === 'button' && (
        <button
          onClick={() => onClick()}
          className={`${styles.btn} ${styles[buttonStyleCssClass[buttonStyle]]} ${hostClass}`}
        >
          {title}
        </button>
      )}
      {type === 'link' && (
        <Link to={toLink} className={`${styles.btn} ${styles[buttonStyleCssClass[buttonStyle]]} ${hostClass}`}>
          {title}
        </Link>
      )}
    </>
  );
};

export default Button;
