import React from 'react';
import styles from './ContentChunks.module.scss';
import { Link, useOutletContext } from 'react-router-dom';

const ContentChunks = () => {
  const bundle = useOutletContext();

  return (
    <>
      {bundle?.chunks?.length ? (
        <ul className={styles.chunksList}>
          {bundle.chunks.map((chunk) => (
            <li className={styles.chunkListItem} key={chunk.order.toString()}>
              <Link to={`${chunk.order}`}>Chunk - {chunk.order}</Link>
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
};

export default ContentChunks;
