import styles from './ToggleButton.module.scss';
import { useState } from 'react';

const ToggleButton = (props) => {
  const {
    left: { label: leftLabel, value: leftValue },
    right: { label: rightLabel, value: rightValue },
    value,
    onChange,
  } = props;
  const [active, setActive] = useState(value);

  function toggleButton(val) {
    onChange(val);
    setActive(val);
  }

  return (
    <div className={styles.toggleButton}>
      <button
        className={`${styles.buttonLeft} ${active === leftValue ? styles.active : ''}`}
        onClick={() => toggleButton(leftValue)}
      >
        {leftLabel}
      </button>
      <button
        className={`${styles.buttonRight} ${active === rightValue ? styles.active : ''}`}
        onClick={() => toggleButton(rightValue)}
      >
        {rightLabel}
      </button>
    </div>
  );
};

export default ToggleButton;
