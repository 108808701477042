import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Outlet } from 'react-router-dom';
import styles from './BundlesPage.module.scss';

const BundlesPage = () => {
  const [bundles, setBundles] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/bundles`).then(
      (response) => {
        setIsLoading(false);
        setBundles(response.data);
      },
      () => {
        setIsLoading(false);
        setError(true);
      }
    );
  }, []);

  return (
    <div className={styles.bundlesPage}>
      <div className={styles.bundlesPageContent}>
        {isLoading && <p>Loading content bundles...</p>}

        {!isLoading && error && <p>An error occurred while loading content bundles. Please try again later...</p>}
        <Outlet context={bundles} />
      </div>
    </div>
  );
};

export default BundlesPage;
